import React from 'react';
import styles from './Hamburger.module.scss';

const Hamburger = ({ active, click }) => {

    const currentStyles = active ? [styles.Hamburger, styles.Open] : [styles.Hamburger];

    return (
        <div className={styles.Container} onClick={click}>
            <div className={currentStyles.join(' ')}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Hamburger;