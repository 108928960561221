import { useState, useEffect } from 'react';

let storeState = {};
let listeners = [];
let actions = {};

export const useStore = () => {
    const setState = useState(storeState)[1];

    const dispatch = actionIdentifier => {
        const newState = actions[actionIdentifier](storeState);
        storeState = {...storeState, ...newState};

        for(const listener of listeners) {
            listener(storeState);
        }
    }

    useEffect(() => {
        listeners.push(setState);
        return () => {
            listeners = listeners.filter(li => li !== setState)
        }
    }, [setState])

    return [storeState, dispatch];
}

export const initStore = () => {
    storeState = {
        loader: true,
        altLang: false,
        whiteNav: false,
        stickyNav: false
    };
    actions = {
        TURN_LOADER_ON: () => { return {loader: true} },
        TURN_LOADER_OFF: () => { return {loader: false} },
        TURN_ALT_LANG_ON: () => { return {altLang: true} },
        TURN_ALT_LANG_OFF: () => { return {altLang: false} },
        TURN_WHITE_NAV_ON: () => { return {whiteNav: true} },
        TURN_WHITE_NAV_OFF: () => { return {whiteNav: false} },
        TURN_STICKY_NAV_ON: () => { return {stickyNav: true} },
        TURN_STICKY_NAV_OFF: () => { return {stickyNav: false} }
    };
}
