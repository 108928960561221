import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './FooterContent.module.scss';

const Link = ({ data }) => {

    const { label, url, blank } = data;

    return blank ? <a href={url} target="_blank" rel="noreferrer noopener">{label}</a> : <NavLink to={url}>{label}</NavLink>
}

const FooterContent = ({ data }) => {

    const { 
        col1_title, 
        address, 
        col1_link, 
        col2_link1, 
        col2_link2, 
        col2_link3,
        col4_link1,
        col4_link2,
        social_media, 
        copyright } = data;

    return (
        <div className={styles.Container}>
            <div className={styles.Title}>
                {col1_title}
            </div>
            <div className={styles.Col1}>
                {address && <div className={styles.Address} dangerouslySetInnerHTML={{__html: address}} />}
                {col1_link && <Link data={col1_link} />}
            </div>
            <div className={styles.Col2}>
                {col2_link1 && <Link data={col2_link1} />}
                {col2_link2 && <Link data={col2_link2} />}
                {col2_link3 && <Link data={col2_link3} />}
            </div>
            <div className={styles.Col3}>
                {social_media &&
                    social_media.map((link, index) => { return link ? <Link data={link} key={index} /> : null })
                }
            </div>
            <div className={styles.Col4}>
                {col4_link1 && <Link data={col4_link1} />}
                {col4_link2 && <Link data={col4_link2} />}
                {copyright && <div className={styles.Copyright} dangerouslySetInnerHTML={{__html: copyright.replaceAll("[year]", new Date().getFullYear())}} />}
            </div>
        </div>
    )
}

export default FooterContent