import React from 'react';
import Link from '../../UI/Link/Link';
// import image from './career_success_v2.jpg';
import styles from './Career.module.scss';

const Success = ({ store, successImage }) => {
    return (
        <div className={styles.Success}>
            <div className={styles.Col1}>
                <div className={styles.Title}>Thank you for your interest in working with us.</div>
                <div className={styles.Text}>We will review your application and a member of our team will get in touch with you.</div>
                <div className={styles.Link}>
                    <Link data={{
                        label: "Back to Work With Us",
                        url: store.altLang ? "/es/work-with-us" : "/work-with-us",
                        blank: false
                    }} />
                </div>
            </div>
            <div className={styles.Col2}>
                <img src={process.env.REACT_APP_BACKEND_URL + successImage} alt="Career Success" /> 
            </div>
        </div>
    )
}

export default Success;
