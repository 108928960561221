const scrollEvents = (scrollState1, scrollState2, navRef) => {
    let xDown = null;                                                        
    let yDown = null;

    setInterval(() => {
        let scrollTop       = window.scrollY,
            headerHeight    = navRef ? navRef.current.offsetHeight : 0;
        if(scrollTop < (headerHeight + 200)) {
            scrollState1(false);
            scrollState2(false);
        }
    }, 1000);

    const scrollEvent = (direction) => {
        let scrollTop       = window.scrollY,
            headerHeight    = navRef.current.offsetHeight;
        if(scrollTop > (headerHeight + 200)) scrollState1(true);
        else scrollState1(false);
        if((scrollTop > (headerHeight + 300)) && direction === 'up') scrollState2(true);
        else scrollState2(false);
    }

    function getTouches(evt) {
    return  evt.touches ||             
            evt.originalEvent.touches;
    }                                                     
                                                                            
    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];                                      
        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY;                                      
    };                                                
                                                                            
    function handleTouchMove(evt) {
        if ( ! xDown || ! yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
                                                                            
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
            if ( xDiff > 0 ) {
                /* right swipe */ 
            } else {
                /* left swipe */
            }                       
        } else {
            if ( yDiff > 0 ) {
                scrollEvent('down');
            } else { 
                scrollEvent('up');
            }                                                                 
        }
        xDown = null;
        yDown = null;                                             
    };

    window.addEventListener("wheel", event => {
        const delta = Math.sign(event.deltaY);
        if(delta === -1) scrollEvent('up');
        if(delta === 1) scrollEvent('down');
    });

    document.addEventListener('touchstart', handleTouchStart, false);        
    document.addEventListener('touchmove', handleTouchMove, false);
}

export default scrollEvents;
