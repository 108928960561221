import React, { useState, useEffect } from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useLocation } from 'react-router-dom';
import styles from './IntroLoader.module.scss';

const IntroLoader = () => {

    const [ currentPercentage, setCurrentPercentage ] = useState(0);
    const location = useLocation();

    useEffect(() => {

        disablePageScroll();

        let perfData = window.performance.timing,
            EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart),
            time = parseInt((EstimatedTime/1000)%60)*100,
            start = 0,
            end = 100,
            current = start,
            range = end - start,
            increment = end > start? 1 : -1,
            stepTime = Math.abs(Math.floor(time / range));

        var timer = setInterval(function() {
            current += increment;
            setCurrentPercentage(current);
            if (current === end) {
                clearInterval(timer);
                setTimeout(() => { enablePageScroll() }, 2000)
            }
        }, stepTime);
    }, [])
    
    return !location.pathname.includes('media-library') ?
        <div className={styles.Container} data-finished={currentPercentage === 100 ? "true" : "false"}>
            <div className={styles.Percentage}>{currentPercentage}<span>%</span></div>
        </div>
    : null
}

export default IntroLoader