import React, { useState } from 'react';
import axios from '../../../axiosInstance';
import styles from './Newsletter.module.scss';

const isEmail = (email) => {
    const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;
    return email ? regex.test(email.trim()) : false;
}

const Newsletter = () => {

    const [ inputValue, setInputValue ] = useState("");
    const [ hardReset, setHardReset ] = useState(false);
    const [ thanks, setThanks ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const changeHandler = (input) => {
        setInputValue(input.target.value);
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        setSending(true);
        if(isEmail(inputValue)) {
            setError(false);
            await axios.post('newsletter-form-entries', { data: { email: inputValue } })
            .then(response => {
                if (response.status === 200) {
                    setThanks(true);
                    setInputValue("");
                    setHardReset(!hardReset);
                }
            }).catch(error => {
                console.log(error);
            });
        } else {
            setError(true);
        }
        setSending(false);
    }
    

    return (
        <form className={styles.Container} onSubmit={submitHandler}>
            <label htmlFor='newsletter'>Join Our Newsletter</label>
            <div className={styles.InputContainer} data-error={error ? "true" : "false"}>
                <input
                    id="newsletter"
                    name="newsletter"
                    value={inputValue}
                    placeholder='Enter your email'
                    onChange={changeHandler}
                />
                <button type="submit" disabled={sending}>→</button>
                {thanks && <div className={styles.Thanks}>Thank you for subscribing to our newsletter!</div>}
                <div className={styles.Spinner}/>
            </div>
        </form>
    )
}

export default Newsletter