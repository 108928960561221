import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { LastLocationProvider } from "react-router-dom-last-location";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from './axiosInstance';
import { htmlentities } from './components/functions';

// Layout
import Layout from './Layout/Layout';

// Containers
import Page from './containers/Page/Page';
import NotFound from './UI/NotFound/NotFound';

import './App.scss';
import "animate.css/animate.min.css";

const App = () => {

  const [routerMap, setRouterMap] = useState({
    basename: process.env.REACT_APP_BASENAME,
    items: null
  });

  useEffect(() => {
    let isCancelled = false;

    const getRoutes = async () => axios.get('routes')
    .then(response => {
      if (response.data) setRouterMap({...routerMap, items: response.data});
    })
    .catch(err => { console.log(err) })

    if (!isCancelled) getRoutes();

    return () => { isCancelled = true; }
  }, [])

  return(
    <>
      { routerMap.items ?
        <HelmetProvider>
          <BrowserRouter basename={routerMap.basename}>
            <LastLocationProvider>
              <Layout>
                <Routes>
                  {routerMap.items.map(item => {
                    return item ?
                      <Route key={item.page_id} path={item.path} exact element={
                        <>
                          <Helmet>
                            {/* Title */}
                            <title>{item.meta_title ? htmlentities(item.meta_title) : `Sordo Madaleno | ${item.title}`}</title>
                            <meta property="og:title" content={item.meta_title ? htmlentities(item.meta_title) : `Sordo Madaleno | ${item.title}`} key="og:title" />
                            <meta name="twitter:title" content={item.meta_title ? htmlentities(item.meta_title) : `Sordo Madaleno | ${item.title}`}/>

                            {/* Keywords */}
                            {item.keywords && <meta name="keywords" content={item.keywords}/>}
                            
                            {/* Description */}
                            {item.meta_description && <meta name="description" content={item.meta_description} /> }
                            {item.meta_description && <meta property="og:description" content={item.meta_description} key="og:description" />}
                            {item.meta_description && <meta name="twitter:description" content={item.meta_description}/>}
                            
                            {/* Image */}
                            {item.share_image && <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + item.share_image} key="og:image" />}
                            {item.share_image && <meta property="og:image:secure_url" content={process.env.REACT_APP_BACKEND_URL + item.share_image} />}
                            {item.share_image && <meta name="twitter:image" content={process.env.REACT_APP_BACKEND_URL + item.share_image}/>}

                            {/* structured data */}
                            <script type="application/ld+json">
                              {item.structured_data && JSON.stringify(item.structured_data)}
                            </script>

                            {/* Index Page */}
                            {process.env.REACT_APP_INDEX === 'false' && <meta name="robots" content="noindex,nofollow"/>}
                          </Helmet>
                          <Page id={item.id} type={item.type} />
                        </>
                      }/>
                    : null
                  })}
                  <Route path="*" element={ <NotFound /> } />
                </Routes>
              </Layout>
            </LastLocationProvider>
          </BrowserRouter>
        </HelmetProvider>
      : null}
    </>
  )
}

export default App;
