import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { useStore } from '../../store'; 
// import { convertTZ } from '../../functions';

import styles from './Clock.module.scss';

const Clock = () => {

    const [ hour, setHour ]= useState("00");
    const [ min, setMin ]= useState("00");
    const [ sec, setSec ]= useState("00");
    const [ formatedDate, setFormatedDate ]= useState(null);
    const [ session, setSession ] = useState("AM");
    const store = useStore()[0];
  
    useEffect(() => {
  
        let date = new Date(); 
        // const convertedDate = convertTZ(date, "America/New_York");
        setFormatedDate(moment(date).locale(store.altLang ? 'es' : 'en').format('dddd, MMMM D, Y'));
        let hh = date.getHours();
        let mm = date.getMinutes();
        let ss = date.getSeconds();
        let session = "a.m";
        
        if(hh >= 12){
            session = "p.m";
        }
        
        if(hh === 0){
            hh = 12;
        }
    
        if(hh > 12){
            hh = hh - 12;
        }
    
        hh = (hh < 10) ? "0" + hh : hh;
        mm = (mm < 10) ? "0" + mm : mm;
        ss = (ss < 10) ? "0" + ss : ss;
        
        setTimeout(function(){ 
            setHour(hh);
            setMin(mm);
            setSec(ss);
            setSession(session);
        }, 1000);
  
    }, [sec])

    return (
        <div className={styles.Container}>
            {formatedDate}, <br className={styles.MobileBr}/>{hour}:{min}:{sec} <span>{session}</span>
        </div>
    )
}

export default Clock