const isEmail = (email) => {
    const regex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;
    return email ? regex.test(email.trim()) : false;
}

const isValidName = (name) => {
    name = name.replaceAll(' ', '').replaceAll('-', '');
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1][a-zA-ZÀ-ÿ\u00f1\u00d1]*$/;
    return name ? regex.test(name.trim()) : false;
}

const isValidPhone = (phone) => {
    const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return phone ? regex.test(phone.trim()) : false;
}

const formValidations = (form, setErrors) => {
    let flag = true;

    if(!isValidName(form.first_name) || !form.first_name) {
        flag = false;
        setErrors(prevState => { return {...prevState, first_name: 'Invalid name'} })
    } else {
        setErrors(prevState => { return {...prevState, first_name: null} })
    }

    if(!isValidName(form.last_name) || !form.last_name) {
        flag = false;
        setErrors(prevState => { return {...prevState, last_name: 'Invalid name'} })
    } else {
        setErrors(prevState => { return {...prevState, last_name: null} })
    }

    if(!isEmail(form.email)) {
        flag = false;
        setErrors(prevState => { return {...prevState, email: 'Invalid email address'} })
    } else {
        setErrors(prevState => { return {...prevState, email: null} })
    }

    if(!isValidPhone(form.phone)) {
        flag = false;
        setErrors(prevState => { return {...prevState, phone: 'Invalid phone number'} })
    } else {
        setErrors(prevState => { return {...prevState, phone: null} })
    }

    if(!form.years_of_experience) {
        flag = false;
        setErrors(prevState => { return {...prevState, years_of_experience: 'Required field'} })
    } else {
        setErrors(prevState => { return {...prevState, years_of_experience: null} })
    }

    if(!form.salary_expectations) {
        flag = false;
        setErrors(prevState => { return {...prevState, salary_expectations: 'Required field'} })
    } else {
        setErrors(prevState => { return {...prevState, salary_expectations: null} })
    }

    return flag;
}

export default formValidations;
