import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Link.module.scss';

const Link = ({ data, white, arrowRotation }) => {

    const {label, url, blank} = data;

    return blank ?

        <a href={url} target="_blank" className={styles.Link} data-white={white ? "true" : "false"} rel="noreferrer">

            {/* <svg viewBox="0 0 17.48 14.95" style={arrowRotation ? {transform: `rotate(${arrowRotation}deg)`} : null}>
                <path d="M14.582-6.44C13.248-5.152,8.211,0,8.211,0H11.27c1.679-1.725,5.175-5.543,7.107-7.5-1.932-1.932-5.428-5.727-7.107-7.452H8.211S13.248-9.8,14.582-8.51H.9v2.07Z" transform="translate(-0.897 14.95)"/>
            </svg> */}

            <span dangerouslySetInnerHTML={{__html: arrowRotation === 180 ? `→ ${label}` : arrowRotation === 90 ? `${label} ↓` : `← ${label}`}}></span>
        </a>
        
    : 
        <NavLink to={url} className={styles.Link} data-white={white ? "true" : "false"}>
        
            {/* <svg viewBox="0 0 17.48 14.95" style={arrowRotation ? {transform: `rotate(${arrowRotation}deg)`} : null}>
                <path d="M14.582-6.44C13.248-5.152,8.211,0,8.211,0H11.27c1.679-1.725,5.175-5.543,7.107-7.5-1.932-1.932-5.428-5.727-7.107-7.452H8.211S13.248-9.8,14.582-8.51H.9v2.07Z" transform="translate(-0.897 14.95)"/>
            </svg> */}

            <span dangerouslySetInnerHTML={{__html: arrowRotation === 180 ? `→ ${label}` : arrowRotation === 90 ? `${label} ↓` : `← ${label}`}}></span>

        </NavLink>
}

export default Link