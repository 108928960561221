import React, { useState, useEffect } from 'react';
import Directory from '../../components/directory';
import axios from '../../axiosInstance';
import TeamHeader from '../../components/TeamHeader/TeamHeader';
import NewsHeader from '../../components/NewsHeader/NewsHeader';
import Career from '../../components/Career/Career';
import { useStore } from '../../components/store';

const Page = ({ id, type }) => {

    const [store, storeDispatch] = useStore();
    const [pageContent, setPageContent] = useState(null);

    useEffect(() => {
        let isCancelled = false;
        if(!store.loader) storeDispatch('TURN_LOADER_ON');
        const getPage = async () => axios.get(`${type}/${id}`, {
            params: { populate: 'deep' }
        })
        .then(response => {
            if (response.data) setPageContent(response.data.data.attributes ? response.data.data.attributes : null)
        })
        .catch(err => { console.log(err) })
        
        if(!isCancelled) getPage();

        window.scroll({ top: 0 });
    
        return () => { isCancelled = true; }
    }, [ id, type ])

    useEffect(() => {
        if(pageContent && store.loader) storeDispatch('TURN_LOADER_OFF');
    }, [ pageContent ])

    let formatedContent = [];
    if(pageContent) {
        if( type === "careers" ) {
            formatedContent = <Career content={pageContent} />
        } else {
            formatedContent = pageContent.components ? pageContent.components.map((component, index) => {
                return <Directory key={index + 1} numb={index + 1} component={component} />;
            }) : [];
        }
    }

    if(type === "teammates" && pageContent) formatedContent.unshift( <TeamHeader key={`team-header-${id}`} content={pageContent} /> );
    if(type === "news" && pageContent) formatedContent.unshift( <NewsHeader key={`news-header-${id}`} content={pageContent} /> );

    return (formatedContent ? formatedContent : <div className="container"></div>);
}

export default Page;
