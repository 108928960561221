import React, { useEffect } from 'react';
import { useStore } from '../../components/store';
import Link from '../Link/Link';
import styles from './NotFound.module.scss';

const NotFound = () => {

    const storeDispatch = useStore()[1];

    useEffect(() => { storeDispatch('TURN_LOADER_OFF') }, [])

    return (
        <section className={styles.Container}>
            <div className='container'>
                <h2>Page not found</h2>
                <p>We’re sorry, we couldn’t find the page you are looking for.</p>
                <div className={styles.Link}>
                    <Link data={{
                        label: "Back Home",
                        url: "/",
                        blank: false
                    }} />
                </div>
            </div>
        </section>
    )
}

export default NotFound