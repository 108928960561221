import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axiosInstance.js';
import { useStore } from '../store.js';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from './Logo.js';
import Hamburger from './Hamburger/Hamburger';
import LangSwitcher from './LangSwitcher';
import NavItems from './NavItems';
import ExtraLinks from './ExtraLinks';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import scrollEvents from './scrollEvents.js';
import styles from './Navbar.module.scss';

const Navbar = () => {

    const [ hardReset, setHardReset ] = useState(false);
    const [ esData, setEsData ] = useState(null);
    const [ enData, setEnData ] = useState(null);
    const [ items, setItems ] = useState([]);
    const [ social, setSocial ] = useState([]);
    const [ extra, setExtra ] = useState([]);
    const [ burgerActive, setBurgerActive ] = useState(false);
    const [scrollState1, setScrollState1] = useState(false);
    const [scrollState2, setScrollState2] = useState(false);
    const navRef = useRef(null);
    const [store, storeDispatch] = useStore();
    const location = useLocation();

    useEffect(() => {
        let isCancelled = false; 

        const getEnNavigation = async () => axios.get('navbar-item', {
            params: { populate: 'deep', locale: 'en' }
        })
        .then(response => { 
            if (response.data) setEnData(response.data);
            setHardReset(!hardReset);
         })
        .catch(err => { console.log(err) });

        const getEsNavigation = async () => axios.get('navbar-item', {
            params: { populate: 'deep', locale: 'es-MX' }
        })
        .then(response => { 
            if (response.data) setEsData(response.data); 
            setHardReset(!hardReset);
        })
        .catch(err => { console.log(err) });
        
        if(!isCancelled) {
            getEnNavigation();
            getEsNavigation();
        }

        // Scroll Events
        scrollEvents(setScrollState1, setScrollState2, navRef);
    
        return () => { isCancelled = true; }
    }, []);

    useEffect(() => {
        if( store.altLang && esData ) {
            setItems(esData.data.attributes.link ? esData.data.attributes.link : []);
            setSocial(esData.data.attributes.social_media ? esData.data.attributes.social_media : []);
            setExtra(esData.data.attributes.extra_links ? esData.data.attributes.extra_links : []);
        }
        if( !store.altLang && enData ) {
            setItems(enData.data.attributes.link ? enData.data.attributes.link : []);
            setSocial(enData.data.attributes.social_media ? enData.data.attributes.social_media : []);
            setExtra(enData.data.attributes.extra_links ? enData.data.attributes.extra_links : []);
        }
    }, [ store.altLang, esData, enData, hardReset ]);

    useEffect(() => {
        if(burgerActive) {
            window.scroll({ top: 0 });
            disablePageScroll();
        } else {
            enablePageScroll();
        }
    }, [ burgerActive ]);

    useEffect(() => {
        if(scrollState2) storeDispatch('TURN_STICKY_NAV_ON');
        else storeDispatch('TURN_STICKY_NAV_OFF')
    }, [ scrollState2 ])

    const toggleBurger = () => { setBurgerActive(!burgerActive) }

    return (
        <header 
            className={styles.Container} 
            ref={navRef} 
            data-scroll-sticky-1={scrollState1 ? "true" : "false"} 
            data-scroll-sticky-2={scrollState2 ? "true" : "false"} 
        >
            <div className="container">
                <nav data-white={store.whiteNav && !scrollState1 ? "true" : "false"} data-burger={burgerActive ? "true" : "false"}>
                    <div className={styles.Logo}>
                        <NavLink to={store.altLang ? "/es/" : "/"} onClick={() => { setBurgerActive(false) }}>
                            <Logo/>
                        </NavLink>
                    </div>
                    <div className={styles.HamburgerContainer}>
                        <Hamburger active={burgerActive} click={toggleBurger} />
                    </div>
                    <div className={styles.MobileMenu}>
                        <NavItems items={items} setBurgerActive={setBurgerActive} />
                        <ExtraLinks extra={extra} social={social} setBurgerActive={setBurgerActive} />
                        <LangSwitcher store={store} location={location} setBurgerActive={setBurgerActive} />
                    </div>
                    <NavItems items={items} setBurgerActive={setBurgerActive} />
                    <LangSwitcher store={store} location={location} setBurgerActive={setBurgerActive} />
                </nav>
            </div>
        </header>
    )
}

export default Navbar
