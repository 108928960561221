import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Loader from '../UI/Loader/Loader';
import IntroLoader from '../UI/IntroLoader/IntroLoader';

import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

import { initStore, useStore } from '../components/store';

initStore();

const Layout = ({ children }) => {

    const [store, storeDispatch] = useStore();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname.includes("/es/")) storeDispatch('TURN_ALT_LANG_ON');
        else storeDispatch('TURN_ALT_LANG_OFF');
    }, [location])

    return (
        <>
            <CustomCursor
                targets={['.cursor-target']}
                customClass='custom-cursor'
                dimensions={15}
                fill='#FFFFFF'
                strokeWidth={1}
                strokeColor="#000000"
                targetScale={1}
                targetOpacity={0}
                opacity={1}
                smoothness={{
                    movement: 0.5,
                    scale: 0.5,
                    opacity: 0.8,
                }}
            />
            <Navbar />
            <main data-lang={store.altLang ? "es-MX" : "en-US"}>
                {children}
            </main>
            <Loader />
            <IntroLoader />
            <Footer />
        </>
    )
}

export default Layout;
