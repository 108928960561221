import React, {lazy, Suspense} from 'react';

// Components
const Cards               = lazy(() => import('./Cards/Cards'));
const CareerList          = lazy(() => import('./CareerList/CareerList'));
const FeaturedNews        = lazy(() => import('./FeaturedNews/FeaturedNews'));
const FeaturedProjects    = lazy(() => import('./FeaturedProjects/FeaturedProjects'));
const FeaturedText        = lazy(() => import('./FeaturedText/FeaturedText'));
const FileLink            = lazy(() => import('./FileLink/FileLink'));
const FileLink2           = lazy(() => import('./FileLink2/FileLink2'));
const FocusedWords        = lazy(() => import('./FocusedWords/FocusedWords'));
const FourColumnsText     = lazy(() => import('./FourColumnsText/FourColumnsText'));
const FullScreenVideo     = lazy(() => import('./FullScreenVideo/FullScreenVideo'));
const FullWidthImage      = lazy(() => import('./FullWidthImage/FullWidthImage'));
const Header              = lazy(() => import('./Header/Header'));
const Header2             = lazy(() => import('./Header2/Header2'));
const HomeHero            = lazy(() => import('./HomeHero/HomeHero'));
const ImageBlock          = lazy(() => import('./ImageBlock/ImageBlock'));
const ImageQuote          = lazy(() => import('./ImageQuote/ImageQuote'));
const Inquiries           = lazy(() => import('./Inquiries/Inquiries'));
const MediaBlock          = lazy(() => import('./MediaBlock/MediaBlock'));
const MediaLibrary        = lazy(() => import('./MediaLibrary/MediaLibrary'));
const NewsGrid            = lazy(() => import('./NewsGrid/NewsGrid'));
const OurValues           = lazy(() => import('./OurValues/OurValues'));
const ProjectGrid         = lazy(() => import('./ProjectGrid/ProjectGrid'));
const ProjectHero         = lazy(() => import('./ProjectHero/ProjectHero'));
const RelatedArticles     = lazy(() => import('./RelatedArticles/RelatedArticles'));
const RelatedProjects     = lazy(() => import('./RelatedProjects/RelatedProjects'));
const StudioTabs          = lazy(() => import('./StudioTabs/StudioTabs'));
const TeamGrid            = lazy(() => import('./TeamGrid/TeamGrid'));
const TeamList            = lazy(() => import('./TeamList/TeamList'));
const TextBlock1          = lazy(() => import('./TextBlock1/TextBlock1'));
const TextBlock2          = lazy(() => import('./TextBlock2/TextBlock2'));
const TextBlock3          = lazy(() => import('./TextBlock3/TextBlock3'));
const TextBlock4          = lazy(() => import('./TextBlock4/TextBlock4'));
const TextBlock5          = lazy(() => import('./TextBlock5/TextBlock5'));
const TextBlock6          = lazy(() => import('./TextBlock6/TextBlock6'));
const TimeLine            = lazy(() => import('./TimeLine/TimeLine'));
const TwoColumnsText      = lazy(() => import('./TwoColumnsText/TwoColumnsText'));
const TwoImages           = lazy(() => import('./TwoImages/TwoImages'));

const Directory = ({ component }) => {
    return (
        <Suspense fallback={<div></div>}>
            {component.__component === 'media.cards'               ? <Cards content={component}/> : null}
            {component.__component === 'feed.career-list'          ? <CareerList content={component}/> : null}
            {component.__component === 'feed.featured-news'        ? <FeaturedNews content={component}/> : null}
            {component.__component === 'feed.featured-projects'    ? <FeaturedProjects content={component}/> : null}
            {component.__component === 'text.featured-text'        ? <FeaturedText content={component}/> : null}
            {component.__component === 'media.file-link'           ? <FileLink content={component}/> : null}
            {component.__component === 'media.file-link2'          ? <FileLink2 content={component}/> : null}
            {component.__component === 'text.focused-words'        ? <FocusedWords content={component}/> : null}
            {component.__component === 'text.four-columns-text'    ? <FourColumnsText content={component}/> : null}
            {component.__component === 'media.full-screen-video'   ? <FullScreenVideo content={component}/> : null}
            {component.__component === 'media.full-width-image'    ? <FullWidthImage content={component}/> : null}
            {component.__component === 'text.header'               ? <Header content={component}/> : null}
            {component.__component === 'text.header2'              ? <Header2 content={component}/> : null}
            {component.__component === 'media.home-hero'           ? <HomeHero content={component}/> : null}
            {component.__component === 'media.image-block'         ? <ImageBlock content={component}/> : null}
            {component.__component === 'media.image-quote'         ? <ImageQuote content={component}/> : null}
            {component.__component === 'text.inquiries'            ? <Inquiries content={component}/> : null}
            {component.__component === 'media.video-block'         ? <MediaBlock content={component}/> : null}
            {component.__component === 'feed.media-library'        ? <MediaLibrary content={component}/> : null}
            {component.__component === 'feed.news-grid'            ? <NewsGrid content={component}/> : null}
            {component.__component === 'media.our-values'          ? <OurValues content={component}/> : null}
            {component.__component === 'feed.project-grid'         ? <ProjectGrid content={component}/> : null}
            {component.__component === 'media.project-hero'        ? <ProjectHero content={component}/> : null}
            {component.__component === 'feed.related-articles'     ? <RelatedArticles content={component}/> : null}
            {component.__component === 'feed.related-projects'     ? <RelatedProjects content={component}/> : null}
            {component.__component === 'media.studio-tabs'         ? <StudioTabs content={component}/> : null}
            {component.__component === 'feed.team-grid'            ? <TeamGrid content={component}/> : null}
            {component.__component === 'feed.team-list'            ? <TeamList content={component}/> : null}
            {component.__component === 'text.text-block1'          ? <TextBlock1 content={component}/> : null}
            {component.__component === 'text.text-block2'          ? <TextBlock2 content={component}/> : null}
            {component.__component === 'text.text-block3'          ? <TextBlock3 content={component}/> : null}
            {component.__component === 'text.text-block4'          ? <TextBlock4 content={component}/> : null}
            {component.__component === 'text.text-block5'          ? <TextBlock5 content={component}/> : null}
            {component.__component === 'text.text-block6'          ? <TextBlock6 content={component}/> : null}
            {component.__component === 'media.time-line'           ? <TimeLine content={component}/> : null}
            {component.__component === 'text.two-columns-text'     ? <TwoColumnsText content={component}/> : null}
            {component.__component === 'media.two-images'          ? <TwoImages content={component}/> : null}
        </Suspense>
    )
}

export default Directory;
