import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Link from '../../UI/Link/Link';
import { useStore } from '../store';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import styles from './NewsHeader.module.scss';

const NewsHeader = ({ content }) => {

    const { title, date, description } = content;
    const store = useStore()[0];

    return (
        <section className={styles.Container}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="container">
                    <div className={styles.Media}>
                        <NavLink to={store.altLang ? "/es/media-library" : "/media-library"}>Media Library</NavLink>
                    </div>
                    <div className={styles.Link}>
                        <Link data={{
                            label: "Back to News",
                            url: store.altLang ? "/es/news" : "/news",
                            blank: false
                        }} //arrowRotation={180} 
                        />
                    </div>
                    <h1>{title}</h1>
                    <div className={styles.Date}>
                        {moment(date).locale(store.altLang ? 'es' : 'en').format('MMMM D, Y')} 
                    </div>
                    { description && <div className={styles.Desc}>{description}</div> }
                </div>
            </ScrollAnimation>
        </section>
    )
}

export default NewsHeader;
