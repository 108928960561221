import React from 'react';
import styles from './Navbar.module.scss';
import { NavLink } from 'react-router-dom';

const LangSwitcher = ({ store, location, setBurgerActive }) => {
    return (
        <div className={styles.LangSwitcher}>
            {/* <NavLink 
                to={store.altLang ? location.pathname.replace('/es/', '/') : null}
                className={({ isActive }) => (!store.altLang ? styles.Active : "")}
                onClick={() => { setBurgerActive(false) }}
            >EN</NavLink>
            <NavLink 
                to={!store.altLang ? '/es' + location.pathname : null}
                className={({ isActive }) => (store.altLang ? styles.Active : "")}
                onClick={() => { setBurgerActive(false) }}
            >ES</NavLink> */}
        </div>
    )
}

export default LangSwitcher