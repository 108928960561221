import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from '../../axiosInstance';
import { useStore } from '../store';
import Clock from './Clock/Clock';
import Newsletter from './Newsletter/Newsletter';
import FooterContent from './FooterContent/FooterContent';
import styles from './Footer.module.scss';

const Footer = () => {

    const [ footer, setFooter ] = useState(null);
    const store = useStore()[0];

    useEffect(() => {
        let isCancelled = false; 
        const getFooter = async () => axios.get('footer', {
            params: { populate: 'deep', locale: store.altLang ? 'es-MX' : 'en' }
        })
        .then(response => {
            if (response.data) setFooter(response.data.data.attributes);
        })
        .catch(err => { console.log(err) })
        
        if(!isCancelled) getFooter();
    
        return () => { isCancelled = true; }
    }, [ store.altLang ]);

    return (
        <section className={styles.Container}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='container'>
                    <div className={styles.Clock}>
                        <Clock/>
                        <Newsletter/>
                    </div>
                    {footer && <FooterContent data={footer} />}
                </div>
            </ScrollAnimation>
        </section>
    )
}

export default Footer