import React from 'react';
import styles from './Career.module.scss';
import { useDropzone } from 'react-dropzone'

const DropFile = ({ label, value, name, formHandler, setHardReset }) => {

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: { 'application/pdf' : ['.pdf'] },
        onDropAccepted: acceptedFiles => {
            if(acceptedFiles) {
                if(acceptedFiles[0].size < 5000000) {
                    formHandler(prevstate => {
                        let newState = prevstate;
                        newState[name] = acceptedFiles[0];
                        return newState;
                    });
                    setHardReset(prevstate => { return !prevstate });
                } else {
                    alert('The file is larger than 5MB.')
                }
            }
        },
        onDropRejected: fileRejections => {
            alert(fileRejections[0]['errors'][0]['message']);
        }
    })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} name={name} />
            <div className={styles.DropZone}>
                <div>{label}</div>
                <div>{value ? value.name : "Attach file"}</div>
                <div>PDF format, max file size: 5 MB</div>
            </div>   
        </div>
    )
}

export default DropFile;
