import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.scss';

const Links = ({ items, setBurgerActive }) => {
    return items.map(({ label, url, blank }, index) => {
        return blank ? 
            <a
                dangerouslySetInnerHTML={{__html: label}}
                href={url}
                target="_blank"
                key={index}
                rel="noreferrer noopener"
                onClick={() => { setBurgerActive(false) }}
            />
        :
            <NavLink 
                dangerouslySetInnerHTML={{__html: label}}
                to={url}
                key={index}
                className={({ isActive }) => (isActive ? styles.Active : "")}
                onClick={() => { setBurgerActive(false) }}
            /> 
    })
}

const ExtraLinks = ({ extra, social, setBurgerActive }) => {
    return (
        <div className={styles.Extra}>
            <div className={styles.Col1}>
                {extra && <Links items={extra} setBurgerActive={setBurgerActive} /> }
            </div>
            <div className={styles.Col2}>
                {social && <Links items={social} setBurgerActive={setBurgerActive} /> }
            </div>
        </div>
    )
}

export default ExtraLinks