import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useStore } from '../store';
import axios from '../../axiosInstance';
import Link from '../../UI/Link/Link';
import Form from './Form';
import Success from './Success';
import styles from './Career.module.scss';

const Career = ({ content }) => {

    const { title, career_description, requirements } = content;
    const [ success, setSuccess ] = useState(false);
    const [ successImage, setSuccessImage ] = useState(null);
    const store = useStore()[0];

    useEffect(() => {
        let isCancelled = false;
        const getImage = async () => axios.get('theme-setting', {
            params: { populate: ['career_success_image'] }
        })
        .then(response => {
            if (response.data) setSuccessImage(response.data.data.attributes.career_success_image.data ? response.data.data.attributes.career_success_image.data.attributes.url : null)
        })
        .catch(err => { console.log(err) })
        
        if(!isCancelled) getImage();
    
        return () => { isCancelled = true; } 
    }, [ store.altLang ])

    return title !== "Work With Us" ?
        <section className={styles.Container}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="container">
                    {success ?
                        <Success store={store} successImage={successImage} />
                    : 
                        <>
                            <div className={styles.Link}>
                                <Link data={{
                                    label: "Work With Us",
                                    url: store.altLang ? "/es/work-with-us" : "/work-with-us",
                                    blank: false
                                }} />
                            </div>
                            { title && <div className={styles.Title} dangerouslySetInnerHTML={{__html: title}} /> }
                            { career_description && <div className={styles.Description} dangerouslySetInnerHTML={{__html: career_description.replaceAll(/\n/ig, '<br>')}} /> }
                            { requirements &&
                                <div className={styles.Requirements}>
                                    <div className={styles.Col1}>WHAT WE’RE LOOKING FOR</div>
                                    <ul className={styles.Col2}>
                                        { requirements.map(( { item }, index ) => {
                                            return item && <li key={index} dangerouslySetInnerHTML={{__html: item}} />
                                        }) }
                                    </ul>
                                </div>
                            }
                            <Form career={title} setSuccess={setSuccess} />
                        </>
                    }
                </div>
            </ScrollAnimation>
        </section>
    : null
}

export default Career;
