import React, { useState, useEffect } from "react";
import DropFile from './DropFile';
import formValidations from './formValidations';
import styles from "./Career.module.scss";
import axios from "../../axiosInstance";

const Form = ({ career, setSuccess }) => {
    const [ hardReset, setHardReset ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ formData, setFormData ] = useState({
        career_title: career,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        years_of_experience: "",
        salary_expectations: "",
        resume: null,
        portfolio: null,
        linkedin: "",
        other_links: "",
    });
    const [ formError, setFormError ] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        years_of_experience: null,
        salary_expectations: null,
    });

    const submitHandler = (event) => {
        event.preventDefault();
        setSending(true);
        
        if(formValidations(formData, setFormError)) {
            // formating data
            const data = {};
            const formatedForm = new FormData();

            for ( var key in formData ) {
                if(key !== 'resume' && key !== 'portfolio') {
                    data[key] = formData[key];
                } else {
                    if(formData[key]) formatedForm.append(`files.${key}`, formData[key], formData[key].name)
                }
            }

            formatedForm.append('data', JSON.stringify(data));

            axios.post('career-form-entries', formatedForm)
            .then(response => {
                if (response.status === 200) {
                    setSuccess( true );
                    window.scroll({top: 0});
                }
                setSending(false);
            }).catch(error => {
                console.log(error);
            });
        } else {
            setSending(false);
        }
    };

    const inputHandler = (event) => {
        let newFormData = formData;
        newFormData[event.target.name] = event.target.value;
        setFormData(newFormData);
        setHardReset((prevState) => { return !prevState });
    };

    useEffect(() => { setHardReset(!hardReset) }, [career])

    return (
        <form onSubmit={submitHandler}>
            <h2>Apply now</h2>
            <div className={styles.Col}>
                <input
                    type="text"
                    value={formData.first_name}
                    name="first_name"
                    placeholder="First name*"
                    onChange={inputHandler}
                    data-error={formError.first_name ? "true" : "false"}
                />
                <input
                    type="text"
                    value={formData.last_name}
                    name="last_name"
                    placeholder="Last name*"
                    onChange={inputHandler}
                    data-error={formError.last_name ? "true" : "false"}
                />
                <input
                    type="email"
                    value={formData.email}
                    name="email"
                    placeholder="Email*"
                    onChange={inputHandler}
                    data-error={formError.email ? "true" : "false"}
                />
                <input
                    type="tel"
                    value={formData.phone}
                    name="phone"
                    placeholder="Phone*"
                    onChange={inputHandler}
                    data-error={formError.phone ? "true" : "false"}
                />
            </div>
            <div className={styles.Col}>
                <input
                    type="text"
                    value={formData.years_of_experience}
                    name="years_of_experience"
                    placeholder="Years of Experience in Related Field*"
                    onChange={inputHandler}
                    data-error={
                        formError.years_of_experience ? "true" : "false"
                    }
                />
                <input
                    type="text"
                    value={formData.salary_expectations}
                    name="salary_expectations"
                    placeholder="Salary Expectations*"
                    onChange={inputHandler}
                    data-error={
                        formError.salary_expectations ? "true" : "false"
                    }
                />
                <DropFile label="Résumé" value={formData.resume} name="resume" formHandler={setFormData} setHardReset={setHardReset} />
                <DropFile label="Porfolio" value={formData.portfolio} name="portfolio" formHandler={setFormData} setHardReset={setHardReset} />
                <input
                    type="text"
                    value={formData.linkedin}
                    name="linkedin"
                    placeholder="LinkedIn Profile"
                    onChange={inputHandler} 
                />
                <textarea
                    value={formData.other_links}
                    name="other_links"
                    placeholder="Other Links and Information"
                    onChange={inputHandler} 
                />
                <button type="submit" disabled={sending}>Submit →</button>
            </div>
        </form>
    );
};

export default Form;
