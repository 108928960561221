import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useLastLocation } from "react-router-dom-last-location";
import { useStore } from '../store';
import Link from '../../UI/Link/Link';
import styles from './TeamHeader.module.scss';

const TeamHeader = ({ content }) => {

    const { name, subtitle } = content;
    const store = useStore()[0];
    const lastLocation = useLastLocation();

    return (
        <section className={styles.Container}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className='container'>
                    <div className={styles.Content}>
                        <div className={styles.Link}>
                            {!lastLocation.lastLocation ? 
                                <Link data={{
                                    label: "Back to Team",
                                    url: store.altLang ? "/es/team" : "/team",
                                    blank: false
                                }} />
                            : lastLocation.lastLocation.pathname === "/foundation" ? 
                                <Link data={{
                                    label: "Back to Foundation",
                                    url: store.altLang ? "/es/foundation" : "/foundation",
                                    blank: false
                                }} />
                            : 
                                <Link data={{
                                    label: "Back to Team",
                                    url: store.altLang ? "/es/team" : "/team",
                                    blank: false
                                }} />
                            }
                        </div>
                        {name && <h1 dangerouslySetInnerHTML={{__html: name}} />}
                        {subtitle && <div className={styles.Subtitle} dangerouslySetInnerHTML={{__html: subtitle}} />}
                    </div>
                </div>
            </ScrollAnimation>
        </section>
    )
}

export default TeamHeader
