import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.scss';

const NavItems = ({ items, setBurgerActive }) => {
    return (
        <div className={styles.Items}>
            {items ? 
                items.map(({ label, url, blank }, index) => {
                    return blank ? 
                        <a
                            dangerouslySetInnerHTML={{__html: label}}
                            href={url}
                            target="_blank"
                            key={index}
                            rel="noreferrer noopener"
                            onClick={() => { setBurgerActive(false) }}
                        />
                    :
                        <NavLink 
                            dangerouslySetInnerHTML={{__html: label}}
                            to={url}
                            key={index}
                            className={({ isActive }) => (isActive ? styles.Active : "")}
                            onClick={() => { setBurgerActive(false) }}
                        /> 
                })
            : null}
        </div>
    )
}

export default NavItems